import React from "react"

import {
  Layout,
  Landing,
  GreenBar,
  Banner,
  SectionVehicle,
  Reviews,
  Trips,
  useHomePageQuery,
  useImageQuery,
  renderSeo,
} from "@nt-websites/navigate-theme"

const OurVehicles = ({ data }) => {
  // extracting our custom hook

  const imageQuery = useImageQuery()
  const homeQuery = useHomePageQuery()

  return (
    <Layout>
      {renderSeo(data.contentfulSeoPageMeta)}
      <div className="hotfix--narrow-banner hotfix--narrow-banner--yachts">
        <Landing
          imageData={imageQuery.grandWildVehiclesBanner.childImageSharp.fluid}
          titleFirst="Our Vehicles"
          buttonSecondURL="#popup"
          buttonStyles={["white", "med-blue"]}
          optMargin="u-margin-top-percent-10"
          variation="dest"
          mobileBanner={true}
        />
      </div>
      <GreenBar />
      <SectionVehicle
        introText={
          "It's about the journey as much as the destination. Wherever your journey takes you, our luxury vehicles will get you there with comfort and style"
        }
        introTitle={
          "Our luxury vehicles offer the ultimate in comfort and style."
        }
        imageOne={imageQuery.grandWildVehicleInterior.childImageSharp.fluid}
        imageTwo={imageQuery.grandWildVehicleSouth.childImageSharp.fluid}
        imageThree={imageQuery.grandWildLuxuryVehicle.childImageSharp.fluid}
        imageFour={imageQuery.grandWildVehicleExterior.childImageSharp.fluid}
        title="Cruise around in your
        own 14-seater luxury
        tour vehicle"
        paragraph="New Zealand has some of the world’s most scenic roads and the best way to experience them is in something special. Make the most of your time on the road relaxing in leather seats with unlimited WIFI, USB charging ports, air conditioning, and large windows to admire the breathtaking views."
        listHeader="Key Features"
        listItems={[
          { label: "Luxury seats" },
          { label: "Personal USB ports" },
          { label: "Unlimited WiFi" },
          { label: "Stereo for your own tunes" },
          { label: "Air conditioning" },
          { label: "Big windows for spectacular views" },
        ]}
        paragraphSecond="Book the vehicle for your own private trip, or come as a small group, or couple and share the experience with other like-minded travellers from around the world. Either way, you'll feel right at home in one of our luxury vehicles."
      />
      <Banner {...data.contentfulSeoPageMeta.bottomBanner} />
      <Reviews />
      <Trips data={homeQuery[0].node.popularTours} headerText="Popular Tours" />
    </Layout>
  )
}

export default OurVehicles

/**
 * We should use seo identifier variables from const PAGE_SEO_IDENTIFIER on this query instead plain strings. . But to do so, we need to pass
 * this data as a context. See LekoArts answer in https://github.com/gatsbyjs/gatsby/issues/10023.
 */
export const query = graphql`
  query {
    contentfulSeoPageMeta(referencedPageIdentifier: { eq: "our-vehicles" }) {
      ...PageMeta
    }
  }
`
